import React, {useEffect, useState} from "react";
import {Card, Typography, Button, Row, Col, Tag, Divider, Space, Avatar, message} from "antd";
import SheetModal from "../components/blocks/sheet-modal";
import PaymentMethods from "../components/payment/payment-methods";
import {useTranslation} from "react-i18next";
import ChallengeCard from "../components/challenges/challenge-card";
import Api from "../api";

const {Title, Text} = Typography;
const {CheckableTag} = Tag;

const categories = ["Minu väljakutsed", "Tasuta", "Tasulised", "Sammuväljakutse", "Treening", "Tervis"];
const bingoChallenge = {
    "id": 999,
    "name": "Bingo challenge",
    "type": "bingo",
    "image": "https://i.imgur.com/ZtMg0aV.jpeg",
    "summary": null,
    "enabled": 1,
    "begin_date": "2025-02-14 00:00:00",
    "end_date": "2025-02-28 00:00:00",
    "created_at": "2025-02-01T17:07:21.000000Z",
    "updated_at": "2025-02-01T17:07:21.000000Z",
    "participants": []
}


const ChallengesPage = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showPurchase, setShowPurchase] = useState(null);
    const [competitions, setCompetitions] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        Api.get('/v2/competitions').then(response => {
            console.log(response.data);

            setCompetitions(response.data);
        })
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategory(selectedCategory === category ? null : category);
    };

    const continuePurchase = () => {
        alert('todo')
    }

    return (
        <div id="challenges">
            <Row type="flex" justify="center">
                <Col span={24} md={24} lg={24} xxl={20}>
                    <div className="text-center">
                        {categories.map((category) => (
                            <CheckableTag
                                className={"recipe-checkable-tag"}
                                key={category}
                                checked={selectedCategory === category}
                                onChange={() => handleCategoryChange(category)}
                            >
                                {category}
                            </CheckableTag>
                        ))}
                    </div>

                    <Divider orientation="left">
                        <Title style={{margin: 0}} level={4}>Aktiviteedid</Title>
                    </Divider>

                    <Row gutter={[16, 16]}>
                        {competitions.map((challenge, index) => {
                            return<Col key={index} xs={24} sm={12} md={12} xl={6}>
                                <ChallengeCard challenge={challenge}/>
                            </Col>
                        })}

                            <Col key={"bingo"} xs={24} sm={12} md={12} xl={6}>
                                <ChallengeCard challenge={bingoChallenge}/>
                            </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ChallengesPage;