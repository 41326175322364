import React, {useEffect, useState} from "react";
import {
    Layout,
    Row,
    Col,
    Card,
    Typography,
    Progress,
    Button,
    Tag,
    List,
    Avatar,
    Skeleton,
    Space,
    Flex,
    Divider
} from "antd";
import Icon, {ClockCircleOutlined, LockOutlined} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {isMobile, round} from "../helpers";
import {useResizeEffect} from "../resize-effect";
import Api from "../api";
import {useTranslation} from "react-i18next";
import moment from "moment";

const {Title, Text} = Typography;

const Courses = ({type}) => {
    const navigate = useNavigate();
    const isMobile = useResizeEffect();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);

        Api.get('/v2/courses').then(res => {
            setLoading(false);


            // Process courses data
            const processedCourses = res.data.map((course, index) => {
                // Determine availability based on the index
                const isAvailable = index < 3 || new Date() >= getNextMonthDate(index - 3);

                return ({
                    ...course,
                    totalTasks: course.tasks.length,
                    completedTasks: course.tasks.filter(task => task.completed).length,
                    isAvailable,
                    availableDate: index < 3 ? null : getNextMonthDate(index - 3),
                })
            });

            setCourses(processedCourses);
        })
    }, []);

    const getNextMonthDate = (offset) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + offset + 1; // Offset from the 4th course onward
        return new Date(year, month, 1); // Constructs the 1st of the given month
    };

    return (
        <div>
            <Row type="flex" justify="center">
                <Col span={24} md={24} xxl={type ? 24 : 20}>
                    {!type && !isMobile && (
                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Koolitusvideod</Title>
                        </Divider>
                    )}

                    <Row gutter={[16, 16]}>
                        {!loading ? courses.map((course) => {
                            const isCompleted = course.completedTasks === course.totalTasks;

                            return (
                                (
                                    <Col xs={12} md={12} lg={type ? 8 : 6} key={course.id}>
                                        <Card
                                            bordered={false}
                                            title={''}
                                            className={'video-card-list ' + (!course.isAvailable ? "unavailable" : "")}
                                            cover={
                                                <img
                                                    alt={`${course.title} Thumbnail`}
                                                    src={course.thumbnail}
                                                />
                                            }
                                        >
                                            <Space direction="vertical" style={{width: "100%"}}>
                                                <Row type="flex" justify="space-between">
                                                    <Col span={24}>
                                                        <Title level={5}>{course.title}</Title>
                                                    </Col>
                                                </Row>

                                                <Row type="flex" justify="space-between">
                                                    <Text className="muted-text">
                                                        Ülesanded: {course.completedTasks}/{course.totalTasks}
                                                    </Text>

                                                    <Text className="muted-text">
                                                        <Icon component={ClockCircleOutlined}/> {course.time} min
                                                    </Text>
                                                </Row>

                                                    <Progress strokeColor={{'0%': '#ee7752', '50%': '#e73c7e'}}
                                                              percent={round((course.completedTasks / course.totalTasks) * 100)}
                                                              percentPosition={{type: 'hidden'}}
                                                              status={round((course.completedTasks / course.totalTasks) * 100) < 100 ? "active" : "success"}/>


                                                <Row type="flex" justify="center">
                                                    {course.completedTasks === 0 ? (
                                                        <Button
                                                            onClick={course.isAvailable ? () => navigate(`/courses/${course.id}`) : ''}
                                                            type={course.isAvailable ? "primary" : "default"}
                                                            >{course.isAvailable ? "Alusta" : <div>
                                                            <LockOutlined/> {moment(course.availableDate).format('DD.MM.YYYY')}
                                                        </div>}</Button>
                                                    ) : (
                                                        <Button type="default" onClick={course.isAvailable ? () => navigate(`/courses/${course.id}`) : ''}>
                                                            {isCompleted ? "Vaata koolitust" : "Jätka"}
                                                        </Button>
                                                    )}
                                                </Row>
                                            </Space>
                                        </Card>
                                    </Col>
                                )
                            )
                        }) : (
                            ([...Array(8).keys()].map(index => (
                                <Col xs={24} md={12} lg={6} key={index}>
                                    <Skeleton className="post-skeleton" title paragraph={{rows: 2}} active/>
                                </Col>
                            )))

                        )}
                    </Row>
                </Col>
            </Row>
        </div>

    );
};

export default Courses;