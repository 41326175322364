import React, {useEffect, useState} from "react";
import {Card, Typography, Table, Button, Row, Col, Tag, Divider, Statistic, Avatar, Progress, Space} from "antd";
import Api from "../api";
import Icon, {TrophyOutlined, TrophyTwoTone} from "@ant-design/icons";
import moment from "moment";
import {useResizeEffect} from "../resize-effect";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import ActivityLeaderboard from "../components/goals/activity-leaderboard";
import StepChallengeCard from "../components/challenges/step-challenge-card";
import RewardsCard from "../components/challenges/rewards-card";
import {useNavigate, useParams} from "react-router-dom";
import BingoChallenge from "../components/challenges/bingo-challenge";
import SalesPage from "../components/challenges/challenge-premium";

const {Title, Text} = Typography;

const challenges = [
    {
        title: "Step challenge", // Just challenge name
        short_description: "Walk at least 10,000 steps today. Walk at least 10,000 steps today...", // Display this as intro text
        description: "Walk at least 10,000 steps today. Walk at least 10,000 steps today...", // This probably should be extensive information about challenge including everything images videos html ?
        price: 0, // If its 0 means its free challenge maybe not needed and use product price field instead
        category: "Treening", // Category to filter challenges can 1 challenge be in multiple categories dno
        duration: "14 päeva", // Duration or when challenge ends dno
        participants: 273, // How many users have joined this challenge
        access: true, // User has access to this challenge (free challenge always has access premium only if bought according product)
        product: 'amember product info here', // Amember product that is linked to this challenge if its premium
        type: 'steps', // This can be either "steps", "bingo" or something else according to this there is different layout and information displayed on frontend
        rewards: [
            {
                id: 1,
                title: "Gold Medal",
                description: "Awarded to the top participant.",
                icon: '🥇',
            },
            {
                id: 2,
                title: "Silver Medal",
                description: "For the second-place finisher.",
                icon: '🥈',
            },
            {
                id: 3,
                title: "Bronze Medal",
                description: "For the third-place finisher.",
                icon: '🥉', // Bronze color
            },
            {
                id: 4,
                title: "Fitlap+ access",
                description: "Access for 1 month",
                icon: '4-7',
            },
            {
                id: 5,
                title: "Discount Coupon",
                description: "20% off coupon",
                icon: '7-10',
            },
            {
                id: 8,
                title: "Leaderboard Badge",
                description: "Special profile badge for top 25 participants.",
                icon: '11-25',
            },
        ]
    },
];

const ChallengePage = () => {
    const [challenge, setChallange] = useState('');
    const [competition, setCompetition] = useState('');
    const params = useParams();
    const [id,] = useState(params.id);
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        // Todo get challenge from api
        setChallange(challenges[0])

        if (id == 999) {
            setCompetition(    {
                title: "Bingo challenge", // Just challenge name
                short_description: "Bingo challenge short description", // Display this as intro text
                description: "Bingo challenge long description ..... aaaa ", // This probably should be extensive information about challenge including everything images videos html ?
                price: 0, // If its 0 means its free challenge maybe not needed and use product price field instead
                category: "bingo", // Category to filter challenges can 1 challenge be in multiple categories dno
                duration: "14 päeva", // Duration or when challenge ends dno
                participants: 273, // How many users have joined this challenge
                access: true, // User has access to this challenge (free challenge always has access premium only if bought according product)
                product: 'amember product info here', // Amember product that is linked to this challenge if its premium
                type: 'bingo', // This can be either "steps", "bingo" or something else according to this there is different layout and information displayed on frontend
                rewards: []
            })
        } else {


            Api.get('/v2/competitions/' + id).then(response => {
                setCompetition(response.data);
            }).catch(err => {
                //navigate('/challenges')
            })
        }
    }, []);

    if (!competition) {
        return false;
    }

    return (
        <div id="challenges">
            <Row type="flex" justify="center">
                <Col span={24} md={24} lg={24} xxl={20}>

                    <Row gutter={[40, 20]}>
                        {competition.type === 'steps' && (
                            <Col span={24} lg={16}>
                                <StepChallengeCard competition={competition} setCompetition={setCompetition}/>

                                <br/>

                                <Card>
                                    <Row>
                                        <ActivityLeaderboard competitionData={competition} tableOnly={true}/>
                                    </Row>
                                </Card>
                            </Col>
                        )}

                        {competition.type === 'bingo' && (
                            <Col span={24} lg={16}>
                                <BingoChallenge competition={competition}/>
                            </Col>
                        )}

                            <Col span={24} lg={8}>
                                <RewardsCard rewardsData={[
                                    {id: 1, title: "Gold Medal", description: "Awarded to the top participant.", icon: '🥇'},
                                    {id: 2, title: "Silver Medal", description: "For the second-place finisher.", icon: '🥈'},
                                    {id: 3, title: "Bronze Medal", description: "For the third-place finisher.", icon: '🥉'}, // Bronze color},
                                    {id: 4, title: "Fitlap+ access", description: "Access for 1 month", icon: '4-7',},
                                    {id: 5, title: "Discount Coupon", description: "20% off coupon", icon: '7-10',},
                                    {id: 8, title: "Leaderboard Badge", description: "Special profile badge for top 25 participants.", icon: '11-25',},
                                ]}/>
                            </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ChallengePage;