import React, {useState} from 'react';
import {Layout, Row, Col, Typography, Button, Card, Carousel, Avatar, Image, Divider} from 'antd';
import PurchaseChallengeModal from "./purchase-challenge-modal";
import {
    BulbOutlined,
    GiftOutlined, HeartOutlined,
    LikeOutlined,
    SmileOutlined,
    TeamOutlined, ThunderboltOutlined,
    UserAddOutlined, UserOutlined
} from "@ant-design/icons";

const {Title, Paragraph, Text} = Typography;
const {Content} = Layout;

const SalesPage = () => {
    const [showPurchase, setShowPurchase] = useState(false);

    return (
        <Layout>
            <PurchaseChallengeModal showPurchase={showPurchase} setShowPurchase={setShowPurchase}/>
            <Content>
                {/* Header Section */}
                <Row justify="center" align="middle" style={{textAlign: 'center', padding: '40px 20px'}}>
                    <Col span={24}>
                        <Title level={1}>Join Our Exciting 30-Day Challenge</Title>
                        <Paragraph>
                            Push your limits, achieve your goals, and transform your life with our exclusive 30-day
                            challenge designed to help you reach new heights.
                        </Paragraph>
                    </Col>
                </Row>

                <Divider/>

                <Row type="flex" justify="center" className="text-center">
                    <Col span={24}>
                        <Title level={3}>Only $4.99</Title>
                        <Paragraph>
                            Sign up now to gain instant access to expert guidance, a supportive community, and daily
                            challenges that will drive you towards success.
                        </Paragraph>
                        <Button type="primary" size="large" onClick={() => setShowPurchase(true)}>Purchase Now</Button>
                    </Col>
                </Row>


                {/* Features Section */}
                <h2 className="text-center" style={{margin: '50px 0', fontSize: '2rem'}}>What You Get?</h2>
                <Row gutter={[16, 16]} justify="center" style={{marginBottom: '50px'}}>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><UserAddOutlined style={{color: '#1890ff'}}/> Expert Guidance</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            Personalized advice from experts to guide you throughout your journey.
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><TeamOutlined style={{color: '#1890ff'}}/> Exclusive Community</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            Access to a private community of like-minded individuals for support and motivation.
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><BulbOutlined style={{color: '#1890ff'}}/> Daily Challenges</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            New challenges every day to keep you engaged and motivated to push forward.
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><GiftOutlined style={{color: '#1890ff'}}/> Bonuses</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            Unlock additional resources, bonuses, and rewards as you progress.
                        </Card>
                    </Col>
                </Row>

                {/* Success Stories Section */}
                <h2 className="text-center" style={{margin: '50px 0', fontSize: '2rem'}}>Success Stories</h2>
                <Row gutter={[16, 16]} justify="center" style={{marginBottom: '50px'}}>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><Avatar style={{marginRight: 6}} icon={<UserOutlined/>}/> John Doe</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            "This challenge changed my life! I never thought I could achieve so much in just 30 days."
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><Avatar style={{marginRight: 6}} icon={<UserOutlined/>}/> Jane Smith</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            "A supportive community and great resources made this journey so rewarding. I achieved all
                            my goals!"
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><Avatar style={{marginRight: 6}} icon={<UserOutlined/>}/> Michael Lee</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            "I didn't believe it at first, but this challenge gave me the structure I needed to take my
                            fitness to the next level."
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} justify="center" style={{marginBottom: '50px'}}>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><Avatar style={{marginRight: 6}} icon={<UserOutlined/>}/> John Doe</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            "This challenge changed my life! I never thought I could achieve so much in just 30 days."
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><Avatar style={{marginRight: 6}} icon={<UserOutlined/>}/> Jane Smith</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            "A supportive community and great resources made this journey so rewarding. I achieved all
                            my goals!"
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><Avatar style={{marginRight: 6}} icon={<UserOutlined/>}/> Michael Lee</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            "I didn't believe it at first, but this challenge gave me the structure I needed to take my
                            fitness to the next level."
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                        <Card
                            title={<><Avatar style={{marginRight: 6}} icon={<UserOutlined/>}/> Michael Lee</>}
                            bordered={false}
                            hoverable
                            bodyStyle={{textAlign: 'center'}}
                        >
                            "I didn't believe it at first, but this challenge gave me the structure I needed to take my
                            fitness to the next level."
                        </Card>
                    </Col>
                </Row>

                {/* Call to Action Section */}
                <Row justify="center" align="middle" style={{backgroundColor: '#f7f7f7', padding: '50px 20px'}}>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <Title level={2} style={{fontSize: '2rem', marginBottom: '20px'}}>Ready to take the
                            challenge?</Title>
                        <Button type="primary" size="large" onClick={() => setShowPurchase(true)}>Purchase Now</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
export default SalesPage;
