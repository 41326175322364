import React, {useEffect, useState} from "react";
import {
    Card,
    Typography,
    Button,
    Row,
    Col,
    Tag,
    Statistic,
    Avatar,
    Progress,
    Space,
    Divider,
    message,
    Popover
} from "antd";
import moment from "moment";
import {useResizeEffect} from "../../resize-effect";
import {AntDesignOutlined, InfoCircleOutlined, InfoOutlined, UserOutlined} from "@ant-design/icons";
import Api from "../../api";
import {filterObject} from "../../helpers";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import CountdownTimer from "./countdown-timer";

const {Title, Text} = Typography;
const {Countdown} = Statistic;

const StepChallengeCard = ({competition, setCompetition}) => {
    const startDate = competition.begin_date ? moment(competition.begin_date) : moment("2025-01-01");
    const endDate = competition.end_date ? moment(competition.end_date) : moment("2025-02-28");
    const today = moment(); // Assuming today is the 3rd day of challenge
    const totalDays = endDate.diff(startDate, "days");
    const daysPassed = today.diff(startDate, "days");
    const progressPercent = (daysPassed / totalDays) * 100;
    const isMobile = useResizeEffect();
    const isChallengedEnd = today.isAfter(endDate); // Returns true if the challenge has ended
    const user = useSelector(state => state.user.data);
    const {t} = useTranslation();
    const [hasJoinedCompetition, setHasJoined] = useState(false);

    useEffect(() => {
        let hasJoined = false;

        if (competition.sorted_participants) {
            if (filterObject(competition.sorted_participants, 'participatable_id', user.user_id)[0]) {
                hasJoined = true
            }
        }

        setHasJoined(!!hasJoined);
    }, [competition]);

    const joinChallenge = () => {
        // Remove user from competition
        if (hasJoinedCompetition) {
            Api.delete('/v2/user/competitions/' + competition.id).then(res => {
                Api.get('/v2/competitions/' + competition.id).then(res => {
                    setCompetition(res.data);
                })

                message.info('Lahkusid võistlusest')

                setHasJoined(false);
            });

            return false;
        } else {
            // Add user to competition
            Api.post('/v2/user/competitions/' + competition.id).then(res => {
                setCompetition(res.data);

                setHasJoined(true);

                message.success(t('challenge.joined'))
            });
        }
    }

    return (
        <Card style={{borderRadius: 10}}>
            <Row gutter={[16, 16]} align="middle" style={{marginBottom: 30}}>
                {competition.image && (
                    <Col span={24} md={8} lg={6}>
                        <Avatar
                            style={{width: "100%"}}
                            shape="square"
                            size={isMobile ? 200 : 160}
                            src={competition.image}
                        />
                    </Col>
                )}

                <Col flex="auto">
                    <Space direction="vertical" style={{width: "100%"}}>

                        <Title level={4} style={{margin: 0}}><strong>{competition.name}</strong></Title>
                        <Text
                            type="secondary">{startDate.format("D. MMM, YYYY")} - {endDate.format("D. MMM, YYYY")}</Text>
                        <Progress strokeColor={{'0%': '#ee7752', '50%': '#e73c7e'}}    percentPosition={{type: 'hidden'}}
                                  percent={parseInt(progressPercent)} status="active"/>

                        <Row style={{gap: 8}}>
                            {!isChallengedEnd &&
                                <Button type={hasJoinedCompetition ? "default" : "primary"} onClick={joinChallenge}>{hasJoinedCompetition ? t('challenge.quit') : t('challenge.join')}</Button>}

                            <Popover trigger="click" title=""
                                     content={<div>Osalege meie igakuises sammu väljakutses. <br/> Saate väljakutse
                                         lõpus preemia võita, tehes kõige rohkem samme</div>}>

                                <Button type="text" icon={<InfoCircleOutlined/>}></Button>
                            </Popover>
                        </Row>
                    </Space>
                </Col>
            </Row>

            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Väljakutse lõpuni</Title>
            </Divider>

            <Row type="flex" align="middle" gutter={[20, 20]}>
                <Col span={24} md={12}>
                    <CountdownTimer isChallengedEnd={isChallengedEnd} endDate={endDate}/>
                </Col>

                {competition.sorted_participants && (
                    <Col span={24} md={12} style={{textAlign: "right"}}>
                        <Statistic title={'Osalejad'}
                                   valueRender={() => (
                                       <Avatar.Group className="avatar-group-min"
                                                     max={{count: 5, popover: {trigger: 'none'}}}>
                                           {competition && competition.sorted_participants.map(participant => {
                                               return (
                                                   <Avatar src={participant.user.avatar} icon={<UserOutlined/>}/>
                                               )
                                           })}
                                       </Avatar.Group>
                                   )}/>
                    </Col>
                )}
            </Row>
        </Card>
    );
};

export default StepChallengeCard;
