import {Divider, message, Row, Space, Typography} from "antd";
import PaymentMethods from "../payment/payment-methods";
import SheetModal from "../blocks/sheet-modal";
import React from "react";
const {Text, Title} = Typography;

const PurchaseChallengeModal = ({showPurchase, setShowPurchase}) => {
    const continuePurchase = () => {
        message.info('todo');
    }

    return (
        <SheetModal footer={false} open={showPurchase} close={() => setShowPurchase(false)}>
            <div>
                <Divider orientation="center">
                    <Title style={{margin: 0}} level={4}>Osta väljakutse</Title>
                </Divider>

                <Space direction="vertical">
                    <Text>Title: <strong>Join Our Exciting 30-Day Challenge</strong></Text>
                    <Text>Price: $4.99</Text>
                    <Text>Access: 1 month</Text>
                </Space>

                <Row type="flex" justify="center" className="text-center">
                    <PaymentMethods product={{
                        "product_name": "premium-single-1m",
                        "price": 5,
                        "type": "single",
                        "id": "2-8",
                        "currency": "EUR",
                        "period": "1m",
                        "methods": [
                            "paypal",
                            "stripe",
                            "fortumo-hosted-dcb",
                            "zlickpay"
                        ],
                        "coupon": null,
                        "coupon_percentage": null,
                        "discount_price": null
                    }} setPaymentMethod={continuePurchase} paymentMethods={[
                        "paypal",
                        "stripe",
                        "fortumo-hosted-dcb",
                        "zlickpay"
                    ]}/>
                </Row>
            </div>
        </SheetModal>
    )
}

export default PurchaseChallengeModal;